<template>
  <div>
    <div class="mt-5">
      <v-card class="pa-3" flat>
        <v-row>
          <v-col cols="12" md="12" class="my-auto">
            <v-img src="@/assets/gambar/no_pic_unitkerja.png"></v-img>
          </v-col>

          <v-col cols="12" md="12">
            <v-card color="yellow lighten-4" flat class="pa-3">
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="1" class="my-auto text-center">
                    <v-icon size="40">mdi-map-marker-radius</v-icon>
                  </v-col>
                  <v-col>
                    <span class="font-weight-black">Alamat</span> <br />
                    <span v-if="refdata.alamat">
                      {{ refdata.alamat }}
                    </span>
                    <span v-else> - </span>
                  </v-col>
                </v-row>
              </v-col>

              <v-divider class="mb-4"></v-divider>

              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    <v-icon left small>mdi-phone-classic</v-icon>
                    <span class="caption">Telepon</span>
                    <div class="font-weight-black caption">
                      <span v-if="refdata.telepon">
                        {{ refdata.telepon }}
                      </span>
                      <span v-else> - </span>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <div>
                    <v-icon left small>mdi-email</v-icon>
                    <span class="caption">Email</span>
                    <div class="font-weight-black caption">
                      <span v-if="refdata.email">
                        {{ refdata.email }}
                      </span>
                      <span v-else> - </span>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <div>
                    <span class="caption">Koordinat</span> <br />
                    <div class="font-weight-black caption">
                      <span v-if="refdata.longitude && refdata.latitude">
                        {{ refdata.longitude }} , {{ refdata.latitude }}
                      </span>
                      <span v-else> - </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["refdata"],
  data: () => ({}),

  mounted() {},

  methods: {},
};
</script>
